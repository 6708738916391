import {bindable, computedFrom} from 'aurelia-framework';

export class ThreatAdvancedAnalytics {
    @bindable public threat: any;

    /**
     * Computed property to generate the description as html, based on markdown input.
     */
    @computedFrom('threat')
    private get threatData(): string {
        if (this.threat)
            return JSON.stringify(this.threat, null, 2);
    }
}
