import { autoinject, bindable, containerless } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import notie from 'notie';
import { AuthenticationProvider } from 'providers/authentication-provider';
import {
    ActivityLogsApiClient,
    BusinessMap,
    CasesApiClient,
    CombinedThreatResult,
    EdrAgentResult,
    EdrAlertGroup,
    EdrApiClient,
    Result,
    ThreatsApiClient,
    ThreatSummary
} from 'services/cyber-api';
import { StateApi } from 'services/state-api';
import Swal from 'sweetalert2';
import { Toast } from 'utilities/toast';
import { Utilities } from 'utilities/utilities';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { EventKeys } from '../../../enums/event-keys';
import { ThreatAction } from '../../../enums/threat-action';
import { environment } from 'utilities/environment';

@autoinject()
@containerless()
export class EdrThreatInboxDetails {
    @bindable() private threatId: string;
    @bindable() private flagged: boolean = false;
    @bindable() private fleetVessels: BusinessMap[];
    @bindable() private threatSummary: ThreatSummary;
    @bindable() private display: boolean = true;

    private activityLogs: Result[];
    private combinedThreat: CombinedThreatResult;
    private threat: EdrAlertGroup;
    private edrAgent: EdrAgentResult;
    private threatStatusName: string;
    private loading: boolean = false;
    private company: string;
    private environment = environment;
    private onThreatsActionSubscription: Subscription;

    constructor(
        private threatsApi: ThreatsApiClient,
        private state: StateApi,
        private router: Router,
        private casesApi: CasesApiClient,
        private edrApi: EdrApiClient,
        private authenticationProvider: AuthenticationProvider,
        private activityLogsApi: ActivityLogsApiClient,
        private eventAggregator: EventAggregator
    ) {
    }

    public async attached() {
        this.onThreatsActionSubscription = this.eventAggregator.subscribe(EventKeys.onThreatsAction, (data) => this.onExternalThreatsAction(data));
        await this.refresh();
    }

    public detached(): void {
        this.onThreatsActionSubscription.dispose();
    }

    public async refresh(): Promise<void> {
        this.loading = true;
        this.threat = null;
        this.activityLogs = undefined;

        // Don't continue if there is no threatId, we won't be able to find information without it
        if (!this.threatId) {
            this.loading = false;
            return;
        }

        this.company = this.state.company();

        // Retrieve threat by id
        try {
            this.combinedThreat = await this.threatsApi.getById(this.threatId, this.company);
            this.threat = this.combinedThreat.edrAlert;
            if (!this.threat) throw new Error(`Threat response for id '${this.threatId}' is null`);
        } catch (error) {
            Toast.error('Threat could not be loaded');
            return;
        }

        // Set the threat status name based on the threat's status
        this.threatStatusName = 'Ongoing';
        if (this.combinedThreat.edrAlert.has_fp_disposition) {
            this.threatStatusName = 'Rejected';
        }
        if (this.combinedThreat.edrAlert.acknowledgement.acknowledged) {
            this.threatStatusName = 'Closed';
        }

        this.edrAgent = await this.edrApi.agentResult(this.threat.last_alert.agent._id, this.company);
        this.activityLogsApi.getForThreat(this.threatId, this.company).then((logs) => {
            this.activityLogs = logs;
            if (this.threat.acknowledgement.comment && this.threat.acknowledgement.acknowledged_by !== 'api_gravitee')
                this.activityLogs.unshift(
                    new Result({
                        action: ThreatAction.AddComment,
                        activityType: 'Threat',
                        companyId: this.company,
                        content: this.threat.acknowledgement.comment ?? null,
                        date: this.threat.acknowledgement.comment_update_time,
                        success: true,
                        userName: 'Forti SOAR'
                    })
                );

        });

        // Finally, now that we're done loading, hide the page-covering loader
        this.loading = false;
    }

    /**
     * Ignore the selected threat.
     */
    private async ignore(): Promise<void> {
        if (this.threatStatusName === 'Closed' || this.threatStatusName === 'Rejected') {
            Toast.warning(`Threat cannot be ignored as it's already closed or ignored`);
            return;
        }

        const dialog = await Swal.fire({
            title: 'Ignore this threat?',
            html: `Ignoring this threat means you consider this threat to be a "false positive". No further actions can be made on ignored threats.`,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No, cancel',
            confirmButtonText: 'Yes, ignore',
            input: 'textarea',
            inputPlaceholder: 'A comment is optional but could be very useful for backtracking'
        });

        // Short-circuit when the dialog was dismissed
        if (dialog.dismiss)
            return;

        this.eventAggregator.publish(EventKeys.beforeThreatAction, {
            action: ThreatAction.Ignore,
            threat: this.threat,
            comment: dialog.value
        });

        this.loading = true;

        try {
            await this.threatsApi.ignore(this.threatId, this.company, dialog.value, undefined, undefined);

            // For optimistic UI:
            // 1. Update the threat's status so the action's result is immediately visible
            this.threatStatusName = 'Rejected';
            // 2. Add an activity log so the UI will immediately display the comment
            this.addActivityLog(ThreatAction.Ignore, dialog.value);

            notie.alert({position: 'bottom', text: `OK, threat is ignored`, type: 'success'});

            this.eventAggregator.publish(EventKeys.onThreatAction, {
                action: ThreatAction.Ignore,
                threat: this.threat,
                comment: dialog.value
            });
        } catch (error) {
            notie.alert({position: 'bottom', text: `Oops, ${error}`, type: 'error'});
        } finally {
            this.loading = false;
        }
    }

    /**
     * Resolve, or close, the selected threat.
     */
    private async close(): Promise<void> {
        if (this.threatStatusName === 'Closed' || this.threatStatusName === 'Rejected') {
            Toast.warning(`Threat cannot be closed as it's already closed or ignored`);
            return;
        }
        const predefinedComment = '- ' + this.activityLogs.filter(x => x.content !== '' && x.action === ThreatAction.AddComment).map(x => x.content).join('\n- ');
        const dialog = await Swal.fire({
            title: 'Mark as closed?',
            html: `Closing a threat means you consider this threat to be "Acknowledged" and no further actions can be applied. If the threat is still detected afterwards, it will pop back up in the future as a new threat.`,
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No, cancel',
            confirmButtonText: 'Yes, close',
            input: 'textarea',
            inputValue: predefinedComment,
            inputPlaceholder: 'A comment is optional but could be very useful for backtracking'
        });

        // Short-circuit when the dialog was dismissed
        if (dialog.dismiss)
            return;

        this.eventAggregator.publish(EventKeys.beforeThreatAction, {
            action: ThreatAction.Close,
            threat: this.threat,
            comment: dialog.value
        });

        this.loading = true;

        try {
            await this.threatsApi.close(this.threatId, this.company, dialog.value);

            // For optimistic UI:
            // 1. Update the threat's status so the action's result is immediately visible
            this.threatStatusName = 'Closed';
            this.threat.acknowledgement.acknowledged = true;
            this.threat.acknowledgement.comment = dialog.value;
            // 2. Add an activity log so the UI will immediately display the comment
            this.addActivityLog(ThreatAction.Close, dialog.value);

            notie.alert({position: 'bottom', text: `OK, threat is closed`, type: 'success'});

            this.eventAggregator.publish(EventKeys.onThreatAction, {
                action: ThreatAction.Close,
                threat: this.threat,
                comment: dialog.value
            });
        } catch (error) {
            notie.alert({position: 'bottom', text: `Oops, ${error}`, type: 'error'});
        } finally {
            this.loading = false;
        }
    }

    private async flag(): Promise<void> {
        this.flagged = true;

        try {
            await this.threatsApi.flag(this.threatId, this.state.company());
            this.addActivityLog(ThreatAction.Flag);
        } catch (error) {
            this.flagged = false;
        }
    }

    private async unflag(): Promise<void> {
        this.flagged = false;

        try {
            await this.threatsApi.unflag(this.threatId, this.state.company());
            this.addActivityLog(ThreatAction.Unflag);
        } catch (error) {
            this.flagged = true;
        }
    }

    private onExternalThreatsAction(data: {
        threats: ThreatSummary[],
        action: ThreatAction,
        comment?: string
    }): void {
        const affectsThisThreat = data.threats.some(t => t.id === this.threat.id);

        // When the action doesn't affect this threat, no changes need to be made to the UI, so short-circuit
        if (!affectsThisThreat)
            return;

        const actionedThreat = data.threats.find(t => t.id === this.threat.id);

        // Update the threat's status so the action's result is immediately visible
        this.threatStatusName = actionedThreat.status;

        // Add an activity log so the UI will immediately display the action + comment
        this.addActivityLog(data.action, data.comment);
    }

    private addActivityLog(action: ThreatAction, comment?: string): void {
        this.activityLogs.unshift(
            new Result({
                action,
                activityType: 'Threat',
                companyId: this.company,
                content: comment ?? null,
                date: new Date(),
                success: true,
                userName: 'you'
            })
        );
    }

   /**
     * Copies the threat's Id to the clipboard
     */
    private copyId(): void {
        try {
            Utilities.copyToClipboard(this.threat._id.toString());
        } catch (error) {
            Toast.warning('Unable to copy Threat Id to clipboard');
            return;
        }

        Toast.info('Threat Id copied to clipboard');
    }

    private navigateToVessel(): void {
        // As routing to a child route of a different parent route is not possible, navigate to the URL fragment instead
        // See https://github.com/aurelia/router/issues/89
        // where https://github.com/aurelia/router/issues/89#issuecomment-282079392 could be a solution.
        this.router.navigate(`/vessels/${this.threatSummary.siteId}`);
    }
}
