import { autoinject, bindable } from 'aurelia-framework';
import {
    EdrAgentResult,
    EdrApiClient,
    EdrContainment,
    EdrHostContainmentState,
} from '../../../../../../services/cyber-api';
import { StateApi } from 'services/state-api';
import Swal from 'sweetalert2';

@autoinject()
export class MachineIsolateThreatRespondListItem {
    @bindable() public edrAgent: EdrAgentResult;
    private EdrHostContainmentStates: typeof EdrHostContainmentState = EdrHostContainmentState;
    private containmentLoading = false;

    constructor(
        private edrApi: EdrApiClient,
        private state: StateApi) {
    }

    public async execute(): Promise<void> {
        this.containmentLoading = true;
        Swal.fire({
            title: 'Isolate a machine/PC',
            html: `<p>This feature allows you to isolate a machine/PC on the vessel.</p>
            <br/>
            <p>After confirming the isolation, this machine/PC would be isolated from other machine(s)/PCs(s) on board which will no longer accept packets from this machine, but you can perform all type of scans on this machine even when it's isolated.</p>
            <div class="w-100 text-left d-flex mt-2">
                    <div class='ml-1 mt-1'>
                      <input type="checkbox" id="confirmation" class="swal2-checkbox m-0 align-top">
                    </div>
                    <label for="confirmation" class="form-check-label ml-2">
                        <small> I confirm that I wish to isolate this machine/PC on the vessel ${this.edrAgent.vesselName}</small>
                    </label>
            </div>
            `,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Confirm',
            preConfirm: () => {
                let confirmation = document.getElementById('confirmation') as HTMLInputElement;
                if (!confirmation.checked)
                    Swal.showValidationMessage('You need to confirm that you wish to isolate the machine/PC.')
                else
                    return {
                        confirm: confirmation.checked
                    };
            },
        }).then((response) => {
            if (response.value) {
                this.edrApi.approveHostContainment(this.edrAgent.agentId, this.state.company()).then((edrContainment: EdrContainment) => {
                    this.edrAgent.containmentState = edrContainment.state;
                });
            }
            this.containmentLoading = false;
        });
    }
}
