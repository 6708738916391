import { StateApi } from 'services/state-api';
import { EdrAgentResult, EdrAlertGroup, EdrApiClient, EdrQuarantineFile, } from 'services/cyber-api';
import { autoinject, bindable } from 'aurelia-framework';
import { Toast } from 'utilities/toast';
import { Utilities } from 'utilities/utilities';

@autoinject
export class ThreatRespond {
    @bindable() public alertGroup: EdrAlertGroup;
    @bindable() public edrAgent: EdrAgentResult;

    public error: string;
    public loading = true;
    public quarantine: EdrQuarantineFile | undefined = undefined;

    constructor(
        private edrApi: EdrApiClient,
        private state: StateApi,
    ) {
    }

    private async attached(): Promise<void> {
        this.loading = true;
        await this.fetchQuarantine();
        this.loading = false;
    }

    private async fetchQuarantine(): Promise<void> {
        try {
            let alertGroupId = this.alertGroup._id;
            this.quarantine = await this.edrApi.quarantineByAlertGroup(alertGroupId, this.state.company());
        } catch (error) {
            const errorMessage = JSON.parse(error.response) as { error: string };
            Toast.error(`Oops, ${errorMessage.error}`);
            this.error = errorMessage.error;
        } finally {
            this.loading = false;
        }
    }

    private copy(value: string): void {
        Utilities.copyToClipboard(value);

        Toast.info('Copied to clipboard');
    }
}
