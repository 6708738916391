import { bindable, BindingEngine, containerless, observable } from 'aurelia-framework';
import { EdrAlert, EdrAlertGroup, EdrApiClient } from 'services/cyber-api';
import { Toast } from 'utilities/toast';
import { Utilities } from 'utilities/utilities';
import { Disposable } from 'aurelia-binding';
import { environment } from '../../../../../utilities/environment';
import { StateApi } from '../../../../../services/state-api';

@containerless()
export class ThreatDetails {
    @bindable() private alertGroup: EdrAlertGroup;
    private alerts: EdrAlert[] = [];
    private totalAlerts: number;
    private eventValues: any;
    @observable
    private selectedThreatIndex = 0;
    private selectedThreat: EdrAlert;
    private loading = true;
    private alertCollectionChangedSubscription: Disposable;
    private environment = environment;
    private readonly take= 50;

    constructor(
        private bindingEngine: BindingEngine,
        private edrApi: EdrApiClient,
        private state: StateApi,
    ) {
    }

    private async alertGroupChanged(): Promise<void> {
        // do the initial fetch
        this.loading = true;
        this.alerts = [];
        this.selectedThreatIndex = 0;
        await this.getAlerts(this.take, 0);
        this.selectedThreatIndexChanged();
        this.loading = false;
    }

    private selectedThreatIndexChanged(): void {
        if (this.alerts.length == 0) {
            return;
        }
        this.selectedThreat = this.alerts[this.selectedThreatIndex];
        this.eventValues = Object.entries(this.selectedThreat.event_values[0]).filter(x=>x[0].startsWith(this.selectedThreat.event_type));

    }

    private async increaseSelectedThreatIndex(): Promise<void> {
        if (this.selectedThreatIndex < this.alerts.length - 1) {
            this.selectedThreatIndex++;
        } else if(this.alerts.length < this.totalAlerts) {
            await this.getAlerts(this.take, this.alerts.length);
            this.selectedThreatIndex++;
        }
    }

    private decreaseSelectedThreatIndex(): void {
        if (this.selectedThreatIndex > 0) {
            this.selectedThreatIndex--;
        }
    }

    private copy(value: string): void {
        Utilities.copyToClipboard(value);

        Toast.info('Copied to clipboard');
    }

    private async getAlerts(take: number, skip: number): Promise<void> {
        try {
            let pagedAlerts = await this.edrApi.alertsByAlertGroup(this.alertGroup._id, this.state.company());
            this.alerts.push(...pagedAlerts.items);
            this.totalAlerts = pagedAlerts.total;
        } catch (error) {
            Toast.error(`Oops, ${error}`);
            // fallback to last alert
            this.alerts = [this.alertGroup.last_alert];
            this.totalAlerts = 1;
        }
    }
}
