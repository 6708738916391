import { autoinject, bindable } from 'aurelia-framework';
import {
    AddFileExclusionRequest,
    EdrAlertGroup,
    EdrApiClient,
    EdrQuarantineFile,
    GetExclusionsResponse,
    QuarantineAction
} from '../../../../../../services/cyber-api';
import { StateApi } from 'services/state-api';
import { Toast } from 'utilities/toast';
import Swal from 'sweetalert2';
import { Utilities } from '../../../../../../utilities/utilities';

@autoinject()
export class ThreatRespondListItem {
    @bindable() public quarantineFile: EdrQuarantineFile;
    @bindable() public alertGroup: EdrAlertGroup;

    public allowActions: boolean;
    private actionExecuting: boolean;
    private policy: GetExclusionsResponse;

    constructor(
        private edrApi: EdrApiClient,
        private state: StateApi) {
    }

    public async execute(): Promise<void> {
        this.policy = await this.edrApi.exclusions(this.state.company());

        // find everything behind the latest slash
        let wildcardValue = this.quarantineFile.file_path.split('\\').pop();
        const response = await Swal.fire({
            title: 'Restore file',
            html: `<p>You need to exclude the file from monitoring before restoring it. Restoring will not work fleetwide, but adding a rule will.</p>
             <input id='file-path' class="swal2-input w-100 m-0 mt-3" value='${this.quarantineFile.file_path}'>
                <div class="w-100 text-left d-flex mt-2">
                    <div class='ml-1 mt-1'><input type="checkbox" id="apply-wildcard" class="swal2-checkbox m-0 align-top"></div><label for="apply-wildcard" class="form-check-label ml-2"><small>Exclude filename with wildcard (*\\${wildcardValue})
- if possible according to rule</small></label>
                  </div>
                  <div class='d-flex w-100 justify-content-between pt-2'>
                    <div>
                      <p id='rule-info' class='text-info small m-2'>
                        Rule is already in place
                      </p>
                    </div>
                  </div>`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No, cancel',
            confirmButtonText: 'Yes, continue',
            preConfirm: async () => {
                return this.addRule((document.getElementById('file-path') as HTMLInputElement).value, this.policy.policyId)
                    .then((_) => {
                        return {
                            filePath: document.getElementById('file-path') as HTMLInputElement
                        };
                    }).catch((errors) => {
                        Swal.showValidationMessage(errors.join('<br>') || 'Something went wrong. Please try again.');
                    })
            },
            didOpen: () => {
                const filePathInput = Swal.getPopup()!.querySelector('#file-path') as HTMLInputElement
                const applyWildcard = Swal.getPopup()!.querySelector('#apply-wildcard') as HTMLInputElement
                const ruleInfo = Swal.getPopup()!.querySelector('#rule-info') as HTMLParagraphElement

                showRuleInfo(this.policy, this.quarantineFile);

                function showRuleInfo(policy: GetExclusionsResponse, quarantineFile: EdrQuarantineFile) {
                    let filePathExcluded = policy.excludedFiles.find(x => x === quarantineFile.file_path)?.length > 0;
                    let wildcardExcluded = policy.excludedFiles.find(x => x === `*\\${wildcardValue}`)?.length > 0;

                    let ruleExists = applyWildcard.checked ? wildcardExcluded : filePathExcluded;

                    if (ruleExists) {
                        ruleInfo.classList.toggle('d-none', false);
                    } else {
                        ruleInfo.classList.toggle('d-none', true);
                    }
                }

                applyWildcard.addEventListener('change', () => {
                    filePathInput.value = applyWildcard.checked ? `*\\${wildcardValue}` : this.quarantineFile.file_path;
                    showRuleInfo(this.policy, this.quarantineFile);
                });
            },
        });

        if (response.value) {
            try {
                this.quarantineFile = await this.edrApi.quarantineAction(
                    this.quarantineFile._id.toString(),
                    this.alertGroup.id,
                    this.state.company(),
                    QuarantineAction.Restore,
                    this.quarantineFile.host._id,
                    this.quarantineFile.file_path);
                Toast.success('successfully restored file');
            } catch (error) {
                console.log(error);
                Toast.error(`Oops, ${error}`);
            }
        }
    }

    private async addRule(filePath: string, policyId: string): Promise<void> {
        let addFileExclusionRequest = new AddFileExclusionRequest({
            policyId: policyId,
            file: filePath
        });

        await this.edrApi.addFileExclusion(this.state.company(), addFileExclusionRequest);
        this.policy = await this.edrApi.exclusions(this.state.company());
        Toast.success('successfully excluded file from monitoring');
    }

    public copyTarget(target: string): void {
        try {
            Utilities.copyToClipboard(target);
        } catch (error) {
            Toast.warning('Unable to copy target to clipboard');
            return;
        }

        Toast.info('Copied to clipboard');
    }
}
